import { Link } from "gatsby";
import React, { useState, useEffect } from "react";
import CookieConsent from "react-cookie-consent";

const GA = () => {
  const [isCookieConsentAccepted, setIsCookieConsentAccepted] = useState(() => {
    if (typeof window !== "undefined") {
      return JSON.parse(window.localStorage.getItem("cookieConsentAccepted")) || false;
    }
    return false;
  });

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.localStorage.setItem("cookieConsentAccepted", JSON.stringify(isCookieConsentAccepted));
    }
  }, [isCookieConsentAccepted]);

  return (
    <>
      {typeof window !== "undefined" && (
        <>
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-S1EGG8B8LN"></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-S1EGG8B8LN');
            `}
          </script>

        </>
      )}

      {!isCookieConsentAccepted && (
        <div className="cookieConsent">
          <CookieConsent
            disableStyles
            buttonClasses="cookie-btn"
            buttonText="Accept"
            onAccept={() => {
              setIsCookieConsentAccepted(true);
            }}
          >
            <p className="cookie-text">
              We use cookies to ensure that we give you the best experience on
              our website.
              <Link to="/privacy" style={{ textDecoration: "underline" }}>
                Privacy Policy
              </Link>
            </p>
          </CookieConsent>
        </div>
      )}
    </>
  );
};

export default GA;
