import React, { Component } from "react"
import { graphql, StaticQuery } from "gatsby"
import { Link } from "gatsby"

import styles from "./scss/navigation.module.scss"
import "./scss/hamburger.css"

class Navigation extends Component {
  state = {
    toggle: false,
  }

  handleClick = () => {
    this.setState(prevState => ({
      toggle: !prevState.toggle,
    }))
  }

  closeMenu = () => {
    this.setState(prevState => ({
      toggle: !prevState.toggle,
    }))
  }

  render() {
    const { toggle } = this.state

    return (
      <nav className={styles.nav}>
        <section className={styles.innerWrapper}>
          <div className={styles.top}>
            <Link
              to="/"
              className={styles.logo}
              aria-label="Content Visit Logo"
            >
              <picture>
                <source
                  srcSet={this.props.logo.logo.fixed.srcWebp}
                  type="image/webp"
                />
                <source
                  srcSet={this.props.logo.logo.fixed.src}
                  type="image/png"
                />
                <img
                  src={this.props.logo.logo.fixed.src}
                  alt={this.props.logo.logo.description}
                  aria-label="Content Visit Logo"
                />
              </picture>
            </Link>
            <button
              onClick={this.handleClick}
              className={`hamburger hamburger--3dxy ${
                toggle ? "is-active" : ""
              }`}
              type="button"
              role="menu"
              aria-owns="span1 span2"
              aria-label="Menu"
            >
              <span id="span1" className="hamburger-box">
                <span id="span2" className="hamburger-inner" />
              </span>
            </button>
          </div>
          <ul className={`${styles.ulLinks} ${toggle ? styles.ulOpen : ""}`}>
            <li>
              <hr />
            </li>
            <li>
              <Link
                to="/about"
                activeClassName={styles.active}
                onClick={this.closeMenu}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                to="/services"
                activeClassName={styles.active}
                onClick={this.closeMenu}
              >
                Services
              </Link>
            </li>
            {/* <li>
              <Link
                to="/pricing"
                activeClassName={styles.active}
                onClick={this.closeMenu}
              >
                Pricing
              </Link>
            </li> */}
            <li>
              <Link
                to="/work"
                activeClassName={styles.active}
                onClick={this.closeMenu}
              >
                Case studies
              </Link>
            </li>
            <li>
              <Link
                to="/articles"
                activeClassName={styles.active}
                onClick={this.closeMenu}
              >
                Articles
              </Link>
            </li>

            <li>
              <Link
                to="/contact"
                activeClassName={styles.active}
                className={styles.highlight}
                onClick={this.closeMenu}
              >
                Get In Touch
              </Link>
            </li>
          </ul>
        </section>
      </nav>
    )
  }
}
export default props => (
  <StaticQuery
    query={graphql`
      query {
        logo: contentfulCompanyLogo {
          logo {
            description
            fixed(width: 250) {
              src
              srcWebp
            }
          }
        }
      }
    `}
    render={({ logo }) => <Navigation logo={logo} {...props} />}
  />
)
