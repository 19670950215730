import React from "react"
import PropTypes from "prop-types"
import styles from "./inner-wrapper.module.scss"

const InnerWrapper = ({ children, width }) => {
  const style = {
    maxWidth: width + "px",
    padding: "25px",
  }
  return (
    <div className={styles.content} style={style}>
      {children}
    </div>
  )
}

InnerWrapper.propTypes = {
  width: PropTypes.number,
}

InnerWrapper.defaultProps = {
  width: 1024,
}

export default InnerWrapper
