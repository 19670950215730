import { Link } from "gatsby"
import React from "react"
import { getDate } from "../util/utilityFunctions"
import InnerWrapper from "./innerWrapper"
import styles from "./scss/footer.module.scss"

const Footer = () => (
  <footer className={styles.footer}>
    <InnerWrapper>
      <h3>Content Visit</h3>
      <p className={styles.blurb}>
        We get people moving in your direction. Get in touch at
        contact(at)contentvisit.com
      </p>

      {/* <h3>Social</h3>
          <ul className={styles.social}>
            <li>
              <a
                href="https://www.instagram.com"
                aria-label="Instagram"
                className={styles.instagram}
              >
                <FaInstagram style={{ fontSize: "1.75em" }} />
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com"
                aria-label="Facebook"
                className={styles.facebook}
              >
                <FaFacebook style={{ fontSize: "1.75em" }} />
              </a>
            </li>
            <li>
              <a
                href="https://www.twitter.com"
                aria-label="Twitter"
                className={styles.twitter}
              >
                <FaTwitterSquare style={{ fontSize: "1.75em" }} />
              </a>
            </li>
          </ul> */}
      <h3>Info</h3>
      <ul className={styles.links}>
        <li>
          <Link to="/privacy">Privacy Policy</Link>
        </li>
        <li>
          <Link to="/accessibility">Accessibility Statment</Link>
        </li>
        <li>&copy; {getDate()} Content Visit. All rights reserved.</li>
        <li>
          Built by{" "}
          <a
            href="https://www.dazzleworks.ie"
            rel="noopener noreferrer nofollow"
          >
            dazzle works
          </a>
        </li>
      </ul>
    </InnerWrapper>
  </footer>
)
export default Footer
